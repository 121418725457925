<template>
  <div class="al-upload-btn">
    <slot>
      <div class="al-upload-btn-def">
        <i /></div>
    </slot>
    <input
      class="al-upload-input"
      type="file"
      :multiple="multiple"
      :accept="accept"
      @change="changeHandler"
    >
  </div>
</template>
<script >
/* eslint-disable */
  import btnMixin from './btn-mixin'
  const COMPONENT_NAME = 'al-upload-btn'
  export default {
    name: COMPONENT_NAME,
    mixins: [btnMixin],
    methods: {
      changeHandler(e) {
        const fileEle = e.currentTarget
        const files = fileEle.files
        if (files) {
          this.$parent.addFiles(files)
          fileEle.value = null
        }
      }
    }
  }
</script>
<style lang="scss">
  .al-upload-btn {
  position: relative;
  overflow: hidden;
}
.al-upload-btn:active .al-upload-btn-def {
  background-color: rgba(0, 0, 0, 0.04);
}
.al-upload-input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  font-size: 0;
  opacity: 0;
}
.al-upload-btn-def {
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  position: relative;
}
.al-upload-btn-def:after {
  content: "";
  pointer-events: none;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .al-upload-btn-def:after {
    width: 200%;
    height: 200%;
    border-radius: 4px;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
  .al-upload-btn-def:after {
    width: 300%;
    height: 300%;
    border-radius: 6px;
    -webkit-transform: scale(0.333);
    transform: scale(0.333);
  }
}
.al-upload-btn-def > i:after,
.al-upload-btn-def > i:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 2px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #666;
}
.al-upload-btn-def > i:after {
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
  transform: translate(-50%, -50%) rotate(90deg);
}

</style>