<template>
  <b-sidebar
    v-if="show"
    id="item-history-sidebar"
    :visible="isItemHistorySidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-close-on-backdrop
    no-header
    right
    @change="(val) => $emit('update:is-item-history-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <b-form-checkbox
          v-model="showPreviousItemHistory"
          name="check-button"
          switch
          inline
          @change="onShowPreviousItemHistoryChange"
        />
        <h5 class="mb-0">
          Item History
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <StockModals
        ref="stockModals"
        :type="stockOutActionType"
        :default-value="defaultValue"
        :is-minus="true"
        :is-still="false"
        @onStockInAndOut="onStockInAndOut"
      />

      <b-overlay
        id="item-histories-overlay-background"
        :show="loading"
        variant="blur"
        :opacity="0.5"
        blur="2px"
        rounded="sm"
      >
        <edit-item-history
          ref="editItemHistoryRef"
          :is-edit-item-history-sidebar-active.sync="isEditItemHistorySidebarActive"
        />
        <item-history-box
          v-for="(itemHistory, index) in itemHistories"
          :key="index"
          :item-history-model="itemHistory"
          @show-edit-item-history="showEditItemHistory"
        />
      </b-overlay>

    </template>
  </b-sidebar>
</template>

<script>

import {
  BSidebar, VBToggle, BFormCheckbox, BOverlay,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { collectionLocalSql } from '@/api/default_models'
import { mapState } from 'vuex'
import { baseURL } from '@/libs/axios'
import {
  Ajax, getApiData, isApiSuccess, RouteConst,
} from '@/libs/ajax'
import {
  historyModelField,
  historyTypeField, itemHistoriesModelField, itemModelField, itemsTypeEnum, mysqlCommonModel,
} from '@/api/field_model'
import AuthMixin from '@/mixins/authMixin'
import StockModals from '../../components/StockModals.vue'
import EditItemHistory from '../editItemHistory/editItemHistory.vue'
import ItemHistoryBox from './components/itemHistoryBox.vue'

export default {
  components: {
    BSidebar,
    BFormCheckbox,
    ItemHistoryBox,
    BOverlay,
    EditItemHistory,
    StockModals,

  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [AuthMixin],
  model: {
    prop: 'isItemHistorySidebarActive',
    event: 'update:is-item-history-sidebar-active',
  },
  props: {
    isItemHistorySidebarActive: {
      type: Boolean,
      required: true,
    },
    // itemModel: {
    //   type: Object,
    //   default: () => {},
    // },

  },
  data() {
    return {
      required,
      stockOutActionType: historyTypeField.stockOut,
      baseURL,
      collectionLocalSql,
      loading: false,
      isEditItemHistorySidebarActive: false,
      showPreviousItemHistory: false,
      defaultValue: 0,
      itemHistoryModel: null,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
    }
  },
  computed: {
    ...mapState({
      itemHistories: state => state.itemHistory.itemHistories,
    }),
  },
  // watch: {
  //   itemModel(v) {
  //     this.itemModel = v
  //   },
  // },
  mounted() {
    // this.getItemHistories()
  },
  methods: {
    async onStockInAndOut(v) {
      console.debug('onStockInAndOut', v)
      const {
        itemModel, itemHistoryModel,
      } = this
      const { qty, note } = v
      const sumOfQty = itemModel[itemModelField.qty]
      const qtyToDeduct = sumOfQty - qty
      const itemId = itemModel[mysqlCommonModel.id]
      const historyModel = {
        [historyModelField.type]: historyTypeField.stockOut,
        [historyModelField.item]: `${itemModel[itemModelField.name]}`,
        [historyModelField.originalId]: `${itemModel[itemModelField.originalId]}`,
        [mysqlCommonModel.itemId]: itemId,
        [historyModelField.result]: `${sumOfQty} to ${qtyToDeduct}`,
        [historyModelField.note]: `${note}`,
      }
      const itemHistory = {
        ...itemHistoryModel,
        ...{
          [itemHistoriesModelField.itemId]: itemId,
          [mysqlCommonModel.userId]: this.userData.id,
          createdAt: new Date(Date.now()),
          note,
        },
      }
      const res = await new Ajax(RouteConst.stockOutItemUrl, {
        body: {
          qty,
          itemId,
          historyModel,
          itemHistory,
        },
      })
      const itemModelCopy = { ...itemModel }
      itemModelCopy[itemModelField.qty] = qtyToDeduct

      const z = { ...itemHistoryModel, ...{ [itemHistoriesModelField.qty]: Math.max(itemHistoryModel[itemHistoriesModelField.qty] - qty) } }
      this.$store.commit('itemHistory/EDIT', z)

      if (isApiSuccess(res)) {
        this.$store.commit('items/updateSingleItem', {
          itemModel: itemModelCopy,
          itemsType: itemsTypeEnum.stock,
        })

        // this.showSuccessMessage('Successfully Stock Out')
        this.$swal({
          position: 'bottom-end',
          icon: 'success',
          title: 'Successfully Stock Out',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    showEditItemHistory(v) {
      if (this.isStockOut) {
        this.$refs.stockModals.$refs['stock-in-modal'].show()
        this.defaultValue = v[itemHistoriesModelField.qty]
        this.itemHistoryModel = { ...v }
      } else {
        this.$refs.editItemHistoryRef.itemHistoryModel = { ...v }
        this.$refs.editItemHistoryRef.itemModel = { ...this.itemModel }
        this.$refs.editItemHistoryRef.originalItemQty = v[itemHistoriesModelField.qty]
        this.$refs.editItemHistoryRef.originalItemPurchasePrice = v[itemHistoriesModelField.purchasePrice]
        this.$refs.editItemHistoryRef.$refs.collectionSelectRef.selected = v.supplier ? {
          ...v.supplier,
          ...{
            value: v.supplier.id,
            title: v.supplier.name,
          },
        } : null
        this.isEditItemHistorySidebarActive = true
      }
      /*
      {
        value: historyModel[commonModelField.id],
        title: historyModel[roomHistoriesModelField.paymentDate],
      } */
    },
    onShowPreviousItemHistoryChange(v) {
      console.debug(v)
      this.getItemHistories()
    },
    async getItemHistories() {
      this.loading = true
      const res = await Ajax(RouteConst.getPaginatedItemHistoriesUrl, {
        body: {
          itemId: this.itemModel.id,
          showHiddenHistory: this.showPreviousItemHistory,
        },
      })
      this.$store.commit('itemHistory/GET', getApiData(res))
      this.loading = false
    },

  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const loading = ref(false)
    const show = ref(true)
    const itemModel = {}
    const isStockOut = ref(false)

    const fetchOptions = async () => {

    }

    const onClick = () => {
      // console.debug(this)
    }

    return {
      isStockOut,
      loading,
      itemModel,
      onClick,
      show,
      fetchOptions,

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#item-history-sidebar {
  .vs__dropdown-menu {
    max-height: 450px !important;
  }

}
</style>
