export default {
  props: {
    multiple: {
      type: Boolean,
      default: true,
    },
    accept: {
      type: String,
      default: 'image/*',
    },
  },
}
