<template>
  <!-- modal -->
  <b-sidebar
    v-if="show"
    id="edit-item-history-sidebar"
    :visible="isEditItemHistorySidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-item-history-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit Item History
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-form-group
            :state="qtyState"
            label-for="qty-input"

            :invalid-feedback="invalidFeedBack"
          >
            <b-form-input
              id="qty-input"
              v-model="itemHistoryModel[itemHistoriesModelField.qty]"
              placeholder="Quantity"
              :state="qtyState"
              required
              type="number"
              :min="0.001"
              number
              step="0.001"
            />
          </b-form-group>

          <div>

            <b-form-group
              v-if="userAllowedClass.isPurchasePriceAllowed(userAllowedList) || isAdmin"
              label-for="purchase-price-input"
            >
              <b-input-group>
                <b-form-input
                  id="purchase-price-input"
                  v-model="itemHistoryModel[itemHistoriesModelField.purchasePrice]"
                  type="number"
                  placeholder="Enter Purchase price"
                  :min="0.000"
                  number
                  step="0.001"
                />
              </b-input-group>

            </b-form-group>

            <b-form-group>
              <flat-pickr
                v-model="itemHistoryModel[itemHistoriesModelField.expireDate]"
                placeholder="Select Expiry date"
                class="form-control"
              />
            </b-form-group>

            <CollectionSelect
              ref="collectionSelectRef"
              :tablename="tableName"
              placeholder-o="Select Supplier"
              @selectedData="(v) => selected = v"
            />

            <b-form-group
              label-for="note-input"
            >
              <b-form-textarea
                v-model="itemHistoryModel[itemHistoriesModelField.note]"
                placeholder="Note..."
              />
            </b-form-group>

            <!-- alert -->
            <b-alert
              v-height-fade.appear
              variant="danger"
              :show="secondInvalidFeedBack.length > 0"
              class="mb-0 mt-1"
            >
              <div class="alert-body">
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                {{ secondInvalidFeedBack }}
              </div>
            </b-alert>
          </div>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :disabled="loading"
              class="mr-2"
              type="submit"
            >
              Update
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              :disabled="loading"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>

          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BFormGroup, BSidebar, VBModal, BFormInput, BAlert, BFormTextarea, BInputGroup, BButton, BForm,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import {
  historyModelField, historyTypeField, itemHistoriesModelField, itemModelField, itemsTypeEnum, mysqlCommonModel,
} from '@/api/field_model'
import { heightFade } from '@core/directives/animations'
import flatPickr from 'vue-flatpickr-component'
import { defaultItemHistoryModel, defaultItemModelVal } from '@/api/default_models'
import { ref, nextTick } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { Ajax, RouteConst } from '@/libs/ajax'
import { isDef } from '@/assets/helpers'
import AuthMixin from '@/mixins/authMixin'
import CollectionSelect from '../collections/CollectionSelect.vue'

export default {
  components: {
    BSidebar,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BAlert,
    flatPickr,
    BInputGroup,
    BForm,
    CollectionSelect,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
    'height-fade': heightFade,
  },
  mixins: [AuthMixin],
  model: {
    prop: 'isEditItemHistorySidebarActive',
    event: 'update:is-edit-item-history-sidebar-active',
  },
  props: {
    isEditItemHistorySidebarActive: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      default: null,
    },

  },
  data() {
    return {
      itemHistoriesModelField,
      historyTypeField,
      options: [],
      loading: false,
      invalidFeedBack: '',
      secondInvalidFeedBack: '',
      selected: '',
      qtyState: null,
    }
  },

  computed: {
    tableName() {
      return 'stockIn'
    },

  },
  watch: {
    selected(v) {
      if (v) {
        this.secondInvalidFeedBack = ''
      }
    },

  },
  methods: {
    showToast(title, text) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'icon-alert-circle',
          text,
          variant: 'danger',
        },
      },
      {
        timeout: 2500,
        position: 'bottom-right',
      })
    },
    resetModal() {
      this.options = [] // via ref
      this.selected = ''
      this.secondInvalidFeedBack = ''
      this.invalidFeedBack = ''
      this.qtyState = null
      // this.reset()
    },
    onSubmit() {
      this.loading = true
      const { originalItemQty, originalItemPurchasePrice } = this
      const qty = this.itemHistoryModel[itemHistoriesModelField.qty]
      const purchasePrice = this.itemHistoryModel[itemHistoriesModelField.purchasePrice]

      const hasQtyChange = isDef(qty) && qty !== originalItemQty

      const hasPurchasePriceChange = isDef(purchasePrice)
          && purchasePrice !== originalItemPurchasePrice

      const historyModels = []

      if (hasQtyChange) {
        historyModels.push({
          [historyModelField.type]: this.isShop
            ? historyTypeField.editStoreQty
            : historyTypeField.editStockQty,
          [historyModelField.item]: `${this.itemModel.name}`,
          [itemModelField.originalId]: this.itemModel.originalId,
          [mysqlCommonModel.itemId]: this.itemModel.id,
          [historyModelField.result]: `${Number(this.originalItemQty)} to ${qty}`,
        })
      }

      if (hasPurchasePriceChange) {
        historyModels.push({
          [historyModelField.type]: historyTypeField.editPurchasePrice,
          [historyModelField.item]: `${this.itemModel.name}`,
          [itemModelField.originalId]: this.itemModel.originalId,
          [mysqlCommonModel.itemId]: this.itemModel.id,
          [historyModelField.result]: `${Number(this.originalItemPurchasePrice)} to ${purchasePrice}`,
        })
      }

      Ajax(RouteConst.editItemHistoryUrl, {
        body: {
          itemHistoryModel: this.itemHistoryModel,
          historyModels,
          qty: this.itemHistoryModel[itemHistoriesModelField.qty],
          purchasePrice: this.itemHistoryModel[itemHistoriesModelField.purchasePrice],
          expireDate: this.itemHistoryModel[itemHistoriesModelField.expireDate],
          supplier: this.selected,
          supplierId: this.selected ? this.selected.id : null,
        },
      }).then(() => {
        this.loading = false
        this.reset()
        this.$store.commit('itemHistory/EDIT', this.itemHistoryModel)
        // const itemModelCopy = { ...this.itemModel }
        const itemModelCopy = this.itemModel

        const { itemHistories } = this.$store.state.itemHistory
        const itemHistoriesCopy = itemHistories.filter(element => element.id !== this.itemHistoryModel.id)

        console.debug({ qty })
        const qtyForItemModel = itemHistoriesCopy.reduce((previousValue, element) => (previousValue + element[itemHistoriesModelField.qty]), 0)

        console.debug({ qtyForItemModel })

        itemModelCopy[itemModelField.qty] = qtyForItemModel + qty

        this.$store.commit('items/updateSingleItem', {
          itemModel: itemModelCopy,
          itemsType: itemsTypeEnum.stock,
        })
        this.$swal({
          position: 'bottom-end',
          icon: 'success',
          title: 'Successfully Edited',
          showConfirmButton: false,
          timer: 1000,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        this.$emit('update:is-edit-item-history-sidebar-active', false)
      })
        .catch(error => {
          this.loading = false
          this.showToast('Error', error.message)
        })
    },
  },
  setup() {
    const itemHistoryModel = { ...defaultItemHistoryModel }
    const itemModel = { ...defaultItemModelVal }
    let originalItemQty = null
    let originalItemPurchasePrice = null
    const isShop = ref(false)
    const show = ref(true)

    const resetHistoryModel = () => {
      itemHistoryModel.value = JSON.parse(JSON.stringify(defaultItemHistoryModel))
      itemModel.value = JSON.parse(JSON.stringify(defaultItemModelVal))
      isShop.value = false
      originalItemQty = null
      originalItemPurchasePrice = null
    }

    const reset = () => {
      show.value = false
      nextTick(() => {
        show.value = true
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetHistoryModel)

    return {
      itemHistoryModel,
      isShop,
      show,
      originalItemQty,
      originalItemPurchasePrice,
      itemModel,
      reset,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#edit-item-history-sidebar {
  width: 350px;
}
</style>
