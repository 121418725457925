import { Ajax, RouteConst } from '@/libs/ajax'

/* eslint-disable class-methods-use-this */
export default class ItemsRepository {
  getPaginatedItems(
    page,
    filterBy,
    sortBy, {
      isShop,
      isLowItems = false,
      isToBeExpired = false,
      itemIn, // []
    },
  ) {
    return Ajax(RouteConst.getPaginatedItemsUrl, {
      body: {
        page,
        filterBy,
        sortBy,
        isShop,
        isLowItems,
        isToBeExpired,
        itemIn,
      },
    })
  }

  getPaginatedSearchItems(
    page,
    q,
    getAllBranch,
    alphabetically,
    filterBy, {
      isShop,
      customBranchId,
    },
  ) {
    return Ajax(RouteConst.getPaginatedSearchItemsUrl, {
      body: {
        page,
        isShop: getAllBranch ? null : isShop,
        q,
        filterBy,
        customBranchId: customBranchId || null,
        alphabetically,
      },
    })
  }
}
