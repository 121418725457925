<template>
  <b-sidebar
    v-if="show"
    id="add-new-item-sidebar"
    :visible="isAddNewItemSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-item-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ !isEdit ? 'Add' : 'Edit' }} Warehouse item
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <al-upload
            v-model="fileUpload"
            :action="`${baseURL}/global/uploadImage`"
            :simultaneous-uploads="1"
            :max="1"
            label="Drop Item image here..."
            @file-success="handleFileProcess"
          />
          <!--
          <file-pond
            ref="pond"
            v-model="fileUpload"
            name="file"
            label-idle="Drop Item image here..."
            accepted-file-types="image/jpeg, image/png"
            :server="`${baseURL}/api/v2/global/uploadImage`"
            :allow-image-preview="true"
            :allow-multiple="false"
            credits="Dunya"
            @processfile="handleFileProcess"
            @init="handleFilePondInit"
          /> -->

          <validation-provider
            v-if="!isEdit"
            #default="validationContext"
            name="Branch"
            rules="required"
          >
            <b-form-group
              label=""
              label-for="branch"
              :state="getValidationState(validationContext)"
            >
              <v-select
                ref="selectRef"
                v-model="itemModel.branchId"
                filterable
                :options="branchOptions"
                :loading="loadingBranches"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                required
                placeholder="Select Branch"
                label="title"
                input-id="branch"
                @input="onBranchChange"
                @click.native="onClick"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Item Name"
            rules="required"
          >

            <b-form-group>
              <b-form-input
                id="item-namee"
                v-model="itemModel.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Item name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group>
            <b-form-input
              id="barcode"
              v-model="itemModel.barcode"
              trim
              placeholder="Barcode"
            />

          </b-form-group>

          <b-form-group>
            <b-form-input
              id="partNo"
              v-model="itemModel.partNo"
              trim
              placeholder="Part No"
            />
          </b-form-group>

          <b-form-group v-if="!isEdit && (userAllowedClass.isPurchasePriceAllowed(userAllowedList) || isAdmin)">
            <b-form-input
              id="purchasePrice"
              v-model="itemModel.purchasePrice"
              trim
              placeholder="Purchase price"
            />
          </b-form-group>

          <b-form-group v-else>
            <b-list-group>
              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
                button
                @click="showItemHistory"
              >
                <div>
                  <span>Edit Purchase</span>
                </div>

                <span class="ml-1">
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="15"
                  />
                </span>

              </b-list-group-item>
            </b-list-group>
          </b-form-group>

          <b-form-group>

            <b-input-group>
              <cleave
                id="unitPrice"
                v-model="itemModel.unitPrice"
                class="form-control"
                :raw="false"
                :options="options.number"
                number
                placeholder="Unit price"
              />
              <b-input-group-append>
                <b-button
                  v-b-toggle.collapse-1
                  variant="secondary"
                  class="btn-icon"
                >
                  <feather-icon icon="ChevronDownIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-collapse
            id="collapse-1"
            class="mt-2"
          >
            <b-card class="border mb-0">
              <b-form-group>
                <cleave
                  id="minPrice"
                  v-model="itemModel.minPrice"
                  class="form-control"
                  :options="options.number"
                  number
                  :raw="false"
                  placeholder="Minimum price"
                />
              </b-form-group>

              <b-form-group>
                <cleave
                  id="maxPrice"
                  v-model="itemModel.maxPrice"
                  :options="options.number"
                  number
                  :raw="false"
                  class="form-control"
                  placeholder="Maximum price"
                />
              </b-form-group>

            </b-card>
          </b-collapse>

          <item-history-page
            ref="itemHistoryPageRef"
            :is-item-history-sidebar-active.sync="isItemHistorySidebarActive"
          />

          <b-form-group v-if="!isEdit">
            <cleave
              id="qty"
              v-model="itemModel.qty"
              :options="options.number"
              number
              :raw="false"
              class="form-control"
              placeholder="Quantity"
            />
          </b-form-group>

          <b-form-group v-else>
            <b-list-group>
              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
                button
                @click="showItemHistory"
              >
                <div>
                  <span>Edit Qty</span>
                </div>

                <span class="ml-1">
                  <span class="text-danger text-bold">{{ itemModel.qty }}</span>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="15"
                  />
                </span>

              </b-list-group-item>
            </b-list-group>
          </b-form-group>

          <b-form-group>
            <b-form-input
              id="location"
              v-model="itemModel.location"
              trim
              placeholder="Location (G6)"
            />
          </b-form-group>

          <app-collapse
            accordion
            type="border"
          >
            <app-collapse-item title="Show all (optional)">
              <b-form-group
                description="Custom item code / File No, eg: (F2-001)"
              >
                <b-form-input
                  id="code"
                  v-model="itemModel.code"
                  trim
                  placeholder="Item code"
                />
              </b-form-group>

              <b-form-group
                v-if="!isEdit"
                description="Keep track of product sell by dates!
                - View products by there upcoming sell by date.
                - Get Notifications before they expire"
              >
                <flat-pickr
                  v-model="itemModel.expireDate"
                  class="form-control"
                  placeholder="Select Expire date"
                  :config="{ altInput: true,altFormat: 'F j, Y', dateFormat: 'Y-m-d',}"
                />
              </b-form-group>

              <b-form-group v-else>
                <b-list-group>
                  <b-list-group-item
                    class="d-flex justify-content-between align-items-center"
                    button
                    @click="showItemHistory"
                  >
                    <div>
                      <span>Edit Expiry date</span>
                    </div>

                    <span class="ml-1">
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="15"
                      />
                    </span>

                  </b-list-group-item>
                </b-list-group>
              </b-form-group>

              <CollectionSelect
                tablename="groups"
                @selectedData="groupSelected"
              />
              <CollectionSelect
                tablename="marks"
                @selectedData="markSelected"
              />
              <CollectionSelect
                tablename="brands"
                @selectedData="brandSelected"
              />

              <b-form-group>
                <b-form-textarea
                  id="description"
                  v-model="itemModel.description"
                  :state="itemModel.description.length <= 200"
                  placeholder="Description"
                  rows="3"
                />
              </b-form-group>

              <b-form-group>
                <b-input-group>
                  <b-form-input
                    id="manufacturer"
                    v-model="itemModel.manufacturer"
                    placeholder="Manufacturer"
                  />
                  <b-input-group-append>
                    <b-button
                      v-b-toggle.collapse-2
                      variant="secondary"
                      class="btn-icon"
                    >
                      <feather-icon icon="ChevronDownIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-collapse
                id="collapse-2"
                class="mt-2"
              >
                <b-card class="border mb-0">
                  <b-form-group>

                    <flat-pickr
                      v-model="itemModel.manufacturerDate"
                      class="form-control"
                      placeholder="Select Manufactured date"
                      :config="{ altInput: true,altFormat: 'F j, Y', dateFormat: 'Y-m-d',}"
                    />
                  </b-form-group>

                </b-card>
              </b-collapse>

              <CollectionSelect
                tablename="measurement"
                @selectedData="measurementSelected"
              />

              <b-form-group>
                <v-select
                  v-model="itemModel.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="statusOption"
                />
              </b-form-group>

            </app-collapse-item>
          </app-collapse>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="gradient-primary"
              class="mr-2"
              :disabled="loading"
              type="submit"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              :disabled="loading"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>

import {
  BSidebar, BForm, BFormGroup, BInputGroup, BFormInput, BFormInvalidFeedback, BButton, BCard, BCollapse, VBToggle, BInputGroupAppend, BFormTextarea, BListGroupItem, BListGroup,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { collectionLocalSql, defaultItemModelVal } from '@/api/default_models'
import { branchTypeField, itemModelField, itemsTypeEnum } from '@/api/field_model'
import store from '@/store'
import { baseURL } from '@/libs/axios'
import {
  Ajax, RouteConst, getApiData, isApiSuccess,
} from '@/libs/ajax'
import AuthMixin from '@/mixins/authMixin'
import ItemHistoryPage from '../itemHistory/itemHistoryPage.vue'
import CollectionSelect from '../collections/CollectionSelect.vue'
import AlUpload from '../../components/upload/AlUpload.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BListGroup,
    vSelect,
    BListGroupItem,
    Cleave,
    BFormTextarea,
    AlUpload,
    BFormInput,
    AppCollapse,
    AppCollapseItem,
    BCard,
    BInputGroup,
    flatPickr,
    BCollapse,
    BFormInvalidFeedback,
    BInputGroupAppend,
    BButton,
    // vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    CollectionSelect,
    ItemHistoryPage,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [AuthMixin],
  model: {
    prop: 'isAddNewItemSidebarActive',
    event: 'update:is-add-new-item-sidebar-active',
  },
  props: {
    isAddNewItemSidebarActive: {
      type: Boolean,
      required: true,
    },
    isShop: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      baseURL,
      collectionLocalSql,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      isItemHistorySidebarActive: false,
      purchasePrice: null,
      unitPrice: null,
      expireDate: null,
      show: true,
      fileUpload: [],
      statusOption: [
        { title: 'Available', value: 0 }, { title: 'UnAvailable', value: 1 },
      ],
    }
  },

  methods: {
    onBranchChange(v) {
      this.itemModel[itemModelField.branchName] = v.name
    },
    groupSelected(v) {
      console.debug('groupSelected', v)
      this.itemModel.groupp = v
      this.itemModel.groupId = v
    },
    markSelected(v) {
      this.itemModel.mark = v
      this.itemModel.markId = v
    },
    brandSelected(v) {
      this.itemModel.brand = v
      this.itemModel.brandId = v
    },
    measurementSelected(v) {
      this.itemModel.measurement = v
      this.itemModel.measurementId = v
    },

    reset() {
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    showItemHistory() {
      this.$refs.itemHistoryPageRef.itemModel = { ...this.itemModel }
      this.$refs.itemHistoryPageRef.getItemHistories()
      this.isItemHistorySidebarActive = true
      if (this.isShop) {
        this.$refs.itemHistoryPageRef.$refs.editItemHistoryRef.isShop = true
      }
    },
    async onSubmit() {
      const userData = store.state.auth.AppActiveUser

      const { isEdit } = this
      let itemModel = { ...this.itemModel }
      // console.debug('hello', itemModel)
      this.loading = true

      itemModel.createdAt = new Date(Date.now())
      if (!isEdit) {
        itemModel.adminId = userData.id
        itemModel.branchId = itemModel.branchId.id
      }
      itemModel.unitPrice = itemModel.unitPrice ? Number(itemModel.unitPrice.toString().split(',').join('')) : 0
      itemModel.purchasePrice = itemModel.purchasePrice ? Number(itemModel.purchasePrice.toString().split(',').join('')) : 0
      itemModel.qty = itemModel.qty ? Number(itemModel.qty.toString().split(',').join('')) : 0
      itemModel.minPrice = itemModel.minPrice ? Number(itemModel.minPrice.toString().split(',').join('')) : 0
      itemModel.maxPrice = itemModel.maxPrice ? Number(itemModel.maxPrice.toString().split(',').join('')) : 0
      itemModel.status = itemModel.status.value

      let res
      if (!isEdit) {
        res = await Ajax(RouteConst.addItemToDBUrl, {
          body: { itemModel },
        })
        itemModel = { ...getApiData(res) }
        console.debug('getApiData(res)', getApiData(res))
        this.$store.commit('items/addSingleItem', { itemsType: itemsTypeEnum.stock, itemModel: getApiData(res) })
      } else {
        const hasUnitPriceChange = this.unitPrice !== itemModel.unitPrice

        res = await Ajax(RouteConst.updateAllItemUrl, {
          body: {
            id: itemModel.id,
            itemModel,
            hasUnitPriceChange,
          },
        })
      }

      this.loading = false
      if (isApiSuccess(res)) {
        // console.debug(getApiData(res))
        if (!isEdit) {
          this.$swal({
            title: 'Item register complete.',
            text: itemModel.name,
            icon: 'success',
            showCancelButton: true,
            confirmButtonText: 'Keep Creating',
            cancelButtonText: 'Item List',
            html:
            `
            <button type="button" class="btn btn-outline-info">${itemModel.id}</button>
            `,
            customClass: {
              confirmButton: 'btn btn-outline-secondary',
              cancelButton: 'btn btn-primary ml-2',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.itemModel = { ...defaultItemModelVal }
              this.reset()
            } else if (result.dismiss === 'cancel') {
              this.$emit('update:is-add-new-item-sidebar-active', false)
            }
          })
        } else {
          this.$store.commit('items/updateSingleItem', {
            itemModel,
            itemsType: itemsTypeEnum.stock,
          })
          this.$swal({
            title: 'Item edit complete.',
            text: itemModel.name,
            icon: 'success',
            showCancelButton: false,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(() => {
            this.itemModel = { ...defaultItemModelVal }
            this.reset()
            this.$emit('update:is-add-new-item-sidebar-active', false)
          })
        }
      } else {
        this.$swal({
          position: 'bottom-end',
          icon: 'error',
          title: `Fail to ${isEdit ? 'edit' : 'add'} please try to refresh the page`,
          showConfirmButton: false,
          timer: 3500,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    handleFileProcess(v) {
      console.debug('handleFileProcess', v)
      if (v && v.response.data) {
        this.itemModel.image = v.response.data.url
        // this.fileUpload = []
      }
    },
    // response: "{"Code":0,"Msg":"ok","status":200,"Data":{"url":"https://res.cloudinary.com/deuvwyiko/image/upload/v1611736610/wwxsxitdh1qxwyhz76ta.jpg","public_id":"wwxsxitdh1qxwyhz76ta"},"total":0,"subCode":null}"

  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const loading = ref(false)
    const branchOptions = ref([])
    const loadingBranches = ref(false)
    const itemModel = ref(JSON.parse(JSON.stringify(defaultItemModelVal)))

    const fetchOptions = async () => {
      const res = await Ajax(RouteConst.getAllBranchesUrl, {
        body: {
          type: props.isShop ? branchTypeField.store : branchTypeField.stock,
        },
      })
      loadingBranches.value = false
      if (isApiSuccess(res)) {
        branchOptions.value = getApiData(res).map(element => ({
          title: element.name, ...element,
        }))
      }
    }

    const onClick = () => {
      // console.debug(this)
      if (branchOptions.value.length <= 0) {
        loadingBranches.value = true
        fetchOptions()
      }
    }

    const resetItemModelData = () => {
      itemModel.value = JSON.parse(JSON.stringify(defaultItemModelVal))
    }

    const branchSelected = v => {
      itemModel.value.branchId = v
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetItemModelData)

    return {
      itemModel,
      branchSelected,
      loading,
      onClick,
      fetchOptions,
      branchOptions,
      loadingBranches,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style scoped>
/* @import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://cdn.jsdelivr.net/npm/vuesax/dist/vuesax.css"); */
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-item-sidebar {
  .vs__dropdown-menu {
    max-height: 450px !important;
  }

}
</style>
