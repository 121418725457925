<template>
  <div
    class="al-upload-file"
    :class="fileCls"
    @click="clickHandler"
  >
    <slot
      :img-style="fileStyle"
      :progress="fileProgress"
    >
      <div
        class="al-upload-file-def"
        :style="fileStyle"
      >
        <feather-icon
          class="alic-wrong"
          icon="XIcon"
          size="14"
          @click.stop="removeFile"
        />

        <div
          class="al-upload-file-state"
          :class="fileStatusCls"
        >
          <feather-icon
            class="al-upload-file-status"
            :class="statusCls"
            :icon="statusCls === 'alic-right' ? 'CheckCircleIcon' : 'AlertCircleIcon'"
            size="24"
          />
          <!-- <i
            class="al-upload-file-status"
            :class="statusCls"
          /> -->
          <span class="al-upload-file-progress">{{ fileProgress }}</span>
        </div>
      </div>
    </slot>
  </div>
</template>
<script>
/* eslint-disable */
import { STATUS_SUCCESS, STATUS_ERROR } from './util'

const COMPONENT_NAME = 'al-upload-file'
const STATUS_CLASS_MAP = {
  success: 'alic-right',
  error: 'alic-warn',
}
const EVENT_CLICK = 'click'
export default {
  name: COMPONENT_NAME,
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fileCls() {
      return `al-upload-file_${this.file.status}`
    },
    fileStatusCls() {
      const { file } = this
      const { status } = file
      if (file.progress >= 0.01 || status === STATUS_SUCCESS || status === STATUS_ERROR) {
        return 'al-upload-file_stat'
      }
    },
    fileStyle() {
      const url = this.file.url || this.file.base64
      if (!url) {
        return
      }
      return {
        'background-image': `url("${url}")`,
      }
    },
    statusCls() {
      const { status } = this.file
      return STATUS_CLASS_MAP[status]
    },
    fileProgress() {
      if (this.statusCls) {
        return '100%'
      }
      const p = Math.min(Math.floor(this.file.progress * 100), 99)
      return `${p}%`
    },
  },
  methods: {
    clickHandler() {
      this.$emit(EVENT_CLICK, this.file)
    },
    removeFile() {
      this.$parent.removeFile(this.file)
    },
  },
}
</script>
<style lang="scss">
.al-upload-file {
  position: relative;
}
.al-upload-file-def {
  position: relative;
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  background: #fff no-repeat 50%;
  background-size: cover;
  border-radius: 2px;
}
.al-upload-file-def > .alic-wrong {
  position: absolute;
  z-index: 2;
  top: -4px;
  right: -4px;
  color: #ffffff;
  height: 22px;
  width: 22px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
}
.al-upload-file-def > .alic-wrong:before {
  display: inline-block;
  -webkit-transform: scale(1.625);
  transform: scale(1.625);
  -webkit-transform-origin: center;
  transform-origin: center;
}
.al-upload-file-state {
  position: relative;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  overflow: hidden;
  opacity: 0;
  background-color: rgba(37, 38, 45, 0.4);
  border-radius: 2px;
  -webkit-transition: opacity 0.1s;
  transition: opacity 0.1s;
}
.al-upload-file-state:before {
  content: ".";
  position: relative;
  left: -50%;
  display: block;
  width: 1px;
  height: 1px;
  margin-left: -1px;
  background-color: rgba(0, 0, 0, 0.1);
}
.al-upload-file_stat {
  opacity: 1;
}
.al-upload-file-status {
  position: relative;
  z-index: 1;
  font-size: 30px;
  display: none;
}
.al-upload-file-status.alic-right {
  display: block;
  color: #5DC889;
}
.al-upload-file-status.alic-warn {
  display: block;
  color: #f43530;
}

.al-upload-file-status.feather-alert-circle,.feather-check-circle  {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #fff;
}

// .al-upload-file-status.alic-right:after,
// .al-upload-file-status.alic-warn:after {
//   content: "";

// }
.al-upload-file-status.alic-right + .al-upload-file-progress,
.al-upload-file-status.alic-warn + .al-upload-file-progress {
  display: none;
}
.al-upload-file-progress {
  color: #fff;
  font-size: 20px;
}

</style>
