<template>
  <b-card
    no-body
    class="m-2"
  >

    <b-card-header
      class="p-1"
      style="background: #F7F8FE; color: black; padding: 0.6rem !important;"
    >
      <h6 class="mb-0">
        {{ createdAt }}
      </h6>
    </b-card-header>
    <b-row class="text-center mx-0">
      <b-col
        cols="6"
        class="border-right d-flex align-items-between flex-column py-1"
        style="cursor: pointer;"
        @click="showEditItemHistory"
      >
        <div class="d-flex justify-content-between align-items-center">
          <div
            style="text-align: start;"
          >

            <h6
              class="font-weight-bolder"
              style="margin-bottom: 0.2rem;"
            >
              {{ qty }}
            </h6>
            <b-card-text class="text-muted">
              Qty
            </b-card-text>
          </div>
          <feather-icon
            icon="ChevronRightIcon"
            class="text-muted"
            size="15"
          />
        </div>

      </b-col>

      <b-col
        v-if="userAllowedClass.isPurchasePriceAllowed(userAllowedList) || isAdmin"
        cols="6"
        class="d-flex align-items-between flex-column py-1"
        style="cursor: pointer;"
        @click="showEditItemHistory"
      >
        <div class="d-flex justify-content-between align-items-center">
          <div
            style="text-align: start;"
          >

            <h6
              class="font-weight-bolder"
              style="margin-bottom: 0.2rem;"
            >
              {{ purchasePrice }}
            </h6>
            <b-card-text class="text-muted">
              Purchase price
            </b-card-text>
          </div>
          <feather-icon
            icon="ChevronRightIcon"
            class="text-muted"
            size="15"
          />
        </div>

      </b-col>

    </b-row>
    <div
      v-if="expireDate && expireDate.length > 0"
      class="d-flex justify-content-between align-items-center border-top py-1"
      style="cursor: pointer; padding: 0.8rem !important;"
      @click="showEditItemHistory"
    >
      <h6 class="font-weight-bolder mb-0">
        Expire date
      </h6>

      <span class="ml-1 d-flex align-items-center">
        <span
          class="text-muted"
        >{{ expireDate }}</span>
        <feather-icon
          style="margin-left: 0.3rem;"
          icon="ChevronRightIcon"
          class="text-muted"
          size="15"
        />
      </span>
    </div>
    <div
      class="d-flex border-top py-1"
      style="padding: 0.3rem !important; padding-left: 0.7rem !important;"
    >
      <span
        class="font-weight-bolder"
        :class="`text-${historyColor}`"
        style="font-size: 11px;"
      >
        {{ historyLabel }}
      </span>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BRow, BCardHeader, BCol,
} from 'bootstrap-vue'
import {
  historyModelField, historyTypeField, itemHistoriesModelField, mysqlCommonModel,
} from '@/api/field_model'
import AuthMixin from '@/mixins/authMixin'
import { isDefAndNotEmpty } from '@/assets/helpers'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BCardHeader,
  },
  mixins: [AuthMixin],
  props: {
    itemHistoryModel: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      itemHistoriesModelField,
    }
  },
  computed: {
    expireDate() {
      return this.itemHistoryModel[itemHistoriesModelField.expireDate]
    },
    qty() {
      return this.itemHistoryModel[itemHistoriesModelField.qty]
    },
    purchasePrice() {
      return this.itemHistoryModel[itemHistoriesModelField.purchasePrice]
    },
    createdAt() {
      const i = this.itemHistoryModel[mysqlCommonModel.createdAt]
      return i && i.length > 0 ? i.slice(0, 10) : i
    },
    historyModel() {
      return this.itemHistoryModel.history
    },
    historyColor() {
      const historyL = ''
      let { historyModel } = this
      let historyModelFromL

      if (isDefAndNotEmpty(historyL)) {
        const type = historyL.split(',')[0]
        const result = historyL.split(',').length > 1 ? historyL.split(',')[1].trim() : ''
        historyModelFromL = {
          [historyModelField.type]: type,
          [historyModelField.result]: result,
        }

        historyModel = historyModelFromL
      } else {
        historyModel = historyModel ?? {}
      }

      switch (historyModel[historyModelField.type]) {
        case historyTypeField.stockIn:
          return 'primary'
        case historyTypeField.transferFromStockToStore:
          return 'success'
        case historyTypeField.transferFromStoreToStock:
          return 'success'
        case historyTypeField.transferFromBranchToBranch:
          return 'success'
        case historyTypeField.initOpeningStock:
          return 'danger'

        default:
          return 'dark'
      }
    },
    historyLabel() {
      let h = ''
      const historyL = ''
      let { historyModel } = this
      let historyModelFromL

      if (isDefAndNotEmpty(historyL)) {
        const type = historyL.split(',')[0]
        const result = historyL.split(',').length > 1 ? historyL.split(',')[1].trim() : ''
        historyModelFromL = {
          [historyModelField.type]: type,
          [historyModelField.result]: result,
        }

        historyModel = historyModelFromL
      } else {
        historyModel = historyModel ?? {}
      }
      if (historyModel[historyModelField.type] === historyTypeField.stockIn) {
        h = 'Stock in'
      }
      if (historyModel[historyModelField.type] === historyTypeField.transferFromStockToStore) {
        h = 'Transfer from {1} Warehouse'
          .replace('{1}', this.getBranchNameForTransferItem(historyModel))
      }
      if (historyModel[historyModelField.type] === historyTypeField.transferFromStoreToStock) {
        h = 'Transfer from {1} Shop'
          .replace('{1}', this.getBranchNameForTransferItem(historyModel))
      }
      if (historyModel[historyModelField.type] === historyTypeField.transferFromBranchToBranch) {
        h = 'Transfer from {1} Branch'
          .replace('{1}', this.getBranchNameForTransferItem(historyModel))
      }
      if (historyModel[historyModelField.type] === historyTypeField.initOpeningStock) {
        h = 'Initial Opening Stock'
      }
      return h
    },
  },
  methods: {
    getBranchNameForTransferItem(historyModel) {
      const resultSplit = historyModel.result.split(' to')
      return resultSplit.length > 0 ? `"${resultSplit[0]}"` : 'Unknown'
    },
    showEditItemHistory() {
      this.$emit('show-edit-item-history', this.itemHistoryModel)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
